import React from "react";
import { useField } from "formik";
import styled from "styled-components";
import CTALink from "../styles/CTALink";
import { Body18 } from "../styles/BodyTypes";
import { black1, gray1, white } from "../styles/Colors";
import uniqueId from "lodash/uniqueId";
import random from "lodash/random";
import { Hidden, Row, Error, Group } from "./SharedStyles";

const Label = styled(Body18)`
  margin: 0 0 0 16px;
  transform: translateY(1px);
`;

const Button = styled.label`
  display: inline-block;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  border: 1px solid ${(props) => (props.disabled ? gray1 : black1)};
  width: 24px;
  height: 24px;
  transition: background-color 0.3s linear;
  cursor: pointer;
  border-radius: ${(props) => (props.type === "radio" ? "50%" : 0)};
  margin-top: 2px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    opacity: 0;

    width: ${(props) => (props.type === "radio" ? `calc(100% - 2px)` : "13px")};
    height: ${(props) =>
      props.type === "radio" ? `calc(100% - 2px)  ` : "12px"};
    border-radius: ${(props) => (props.type === "radio" ? "50%" : 0)};
    background: ${(props) =>
        props.type === "radio" ? black1 : `url("/images/icon-checkbox.svg")`}
      center center no-repeat;
    background-size: contain;
  }

  &:hover {
    background-color: ${(props) =>
      props.disabled ? gray1 : props.type === "radio" ? white : black1};

    &:before {
      opacity: 1;
    }
  }
`;

const InputHidden = styled.input`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  z-index: -1;

  &:checked ~ ${Button} {
    background-color: ${(props) =>
      props.disabled ? gray1 : props.type === "radio" ? white : black1};

    &:before {
      opacity: 1;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-gap: 12px;

  > *:nth-child(2) {
    margin-left: 16px;
    margin-bottom: 5px;
  }
`;

const CheckBox = ({
  children,
  disabled,
  link,
  linkTag,
  type,
  title,
  ...props
}) => {
  const [field, meta] = useField({ ...props, type: type });
  const id = uniqueId(random(0, 99));
  return (
    <Row style={{ alignItems: "flex-start" }} disabled={disabled}>
      <InputHidden
        id={id}
        type={type}
        disabled={disabled}
        {...field}
        {...props}
      />
      <Button type={type} disabled={disabled} htmlFor={id}>
        <Hidden>{title}</Hidden>
      </Button>

      <Grid>
        <Group>
          <Label disabled={disabled}>
            {!link && <>{title}</>}
            {link && (
              <CTALink attrs={link} href={link}>
                {title}
              </CTALink>
            )}
          </Label>
          {meta.touched && meta.error && (
            <Error>{typeof meta.error === "string" ? meta.error : ""}</Error>
          )}
        </Group>
        {children && field.checked && children}
      </Grid>
    </Row>
  );
};
export default CheckBox;
