import React from "react";
import Alert from "../Alert";

const SALES_EMAIL_ADDRESS = "sales@stackcommerce.com"

const ContactError = () => {
  return (
    <Alert>
      Sorry, we can't process your contact at this time. If you feel you've received this message in error, please contact us at {SALES_EMAIL_ADDRESS}
    </Alert>
  );
};

export default ContactError;
