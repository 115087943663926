import React, { useEffect, useState } from "react";
import HeroLeadGen from "../HeroLeadGen";
import PublisherGenContent from "./PublisherGenContent";
import BrandFormSelection from "./BrandFormSelection";
import Submission from "../Submission";
import styled from "styled-components";
import { gray4 } from "../styles/Colors";
import FormContainer from "./FormContainer";
import { Body18 } from "../styles/BodyTypes";
import ArrowButton from "../ArrowButton";

const Background = styled.div`
  background-color: ${gray4};
  padding: 80px 0;
`;

const Row = styled.span`
  display: block;
  text-transform: capitalize;
  margin-top: 12px;
`;

const FormsWrapper = ({ formToOpen }) => {
  const [current, setCurrent] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!mounted) {
      setCurrent(formToOpen);
      setFormSubmitted(false);
      setMounted(true);
    }
  }, [mounted, formToOpen]);

  return (
    <>
      <HeroLeadGen setCurrent={setCurrent} setFormSubmitted={setFormSubmitted} current={current} />

      {formSubmitted && <Submission />}

      {current === "publisher" && !formSubmitted &&(
        <Background>
          <PublisherGenContent setFormSubmitted={setFormSubmitted} />
        </Background>
      )}

      {current === "brand" && !formSubmitted && <BrandFormSelection setFormSubmitted={setFormSubmitted} />}

      {current === "customer" && !formSubmitted && (
        <Background>
          <FormContainer>
            <Body18>Thanks for getting in touch!</Body18>
            <Body18>
              We are happy to help you with any questions or concerns. <br />
              See below to get started.
              <Row>
                <ArrowButton
                  attrs={{
                    href: "https://stackcommerce.zendesk.com/hc/en-us",
                    rel: "noopener noreferrer",
                    target: "_blank",
                    as: "a"
                  }}
                >
                  Visit our help desk
                </ArrowButton>
              </Row>
            </Body18>
          </FormContainer>
        </Background>
      )}
    </>
  );
};

export default FormsWrapper;
