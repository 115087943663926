import React from "react";
import styled from "styled-components";
import Container from "./styles/Container";
import { Eyebrows20 } from "./styles/Eyebrows";
import { Headline64 } from "./styles/Headings";
import { mediaMedium } from "./styles/variables";
import { black1, brandColor3, gray1, white } from "./styles/Colors";
import LinesFromRight from "./LinesFromRight";

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding: 39px 0 93px;
  background-color: ${white};

  @media (${mediaMedium}) {
    padding: 122px 0 88px;
  }
`;

const Heading = styled(Eyebrows20)`
  margin-bottom: 21px;

  @media (${mediaMedium}) {
    margin-bottom: 27px;
  }
`;

const Title = styled(Headline64)`
  display: grid;

  @media (${mediaMedium}) {
    grid-template-columns: auto 1fr;
    grid-gap: 43px;
  }
`;

const Selection = styled.span`
  display: inline-grid;
  margin-top: 30px;
  max-width: 210px;

  @media (${mediaMedium}) {
    margin-top: 0;
  }
`;

const Section = styled.span`
  margin: 3px 0;

  > span {
    cursor: pointer;
    font-weight: 800;
    position: relative;
    color: ${(props) => (props.current ? black1 : gray1)};
    display: inline;

    span {
      position: relative;
      z-index: 2;
    }

    &:after {
      content: " ";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      box-shadow: inset 0 -0.325em ${white}, inset 0 -0.475em ${brandColor3};
      opacity: ${(props) => (props.current ? 1 : 0)};
      transition: 0.2s opacity;
    }
  }

  &:hover {
    > span {
      &:after {
        opacity: 1;
      }
    }
  }

  @media (${mediaMedium}) {
    margin: 0;

    &:nth-child(2) {
      margin: 2px 0;
    }
  }
`;

const Decoration = styled.div`
  position: absolute;
  bottom: 0;
  width: 50%;
  left: 45%;
  top: 32%;
  display: none;

  @media (${mediaMedium}) {
    display: block;
  }
`;

const SelectionOption = ({ current, onSelect, option, description }) => {
  const isEnabled = current === option;

  return (
    <Section
      onClick={() => onSelect(option)}
      current={isEnabled}
      aria-disabled={!isEnabled}
      role="button"
    >
      <span>
        <span>{description}</span>
      </span>
    </Section>
  );
};

const HeroLeadGen = ({ current, setCurrent, setFormSubmitted }) => {
  const handleSelection = (option) => {
    setCurrent(option);
    setFormSubmitted(false);
  };

  return (
    <Wrapper>
      <Container>
        <Heading as="h1">Work with us</Heading>
        <Title as="div">
          <span>I am a</span>
          <Selection>
            <SelectionOption
              current={current}
              onSelect={handleSelection}
              option="publisher"
              description="Publisher"
            />
            <SelectionOption
              current={current}
              onSelect={handleSelection}
              option="brand"
              description="Brand"
            />
            <SelectionOption
              current={current}
              onSelect={handleSelection}
              option="customer"
              description="Customer"
            />
          </Selection>
        </Title>
      </Container>
      <Decoration>
        <LinesFromRight />
      </Decoration>
    </Wrapper>
  );
};

export default HeroLeadGen;
