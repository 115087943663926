import React from "react";
import styled from "styled-components";
import Container from "../styles/Container";
import { Headline16 } from "../styles/Headings";
import FormContainer from "./FormContainer";
import { mediaMedium } from "../styles/variables";
import { black1, black2, brandColor3, brandColor3Tint } from "../styles/Colors";
import TalkToUsPublishers from "./TalkToUsPublishers";

const Center = styled.div`
  margin: 0 auto;
  display: flex;

  @media (${mediaMedium}) {
    width: calc(100% * 8 / 12 + 28px);
    padding: 0 60px;
  }
`;

const Title = styled(Headline16)`
  display: inline-block;
  margin: 0;
  border-bottom: 4px solid transparent;
  padding-bottom: 8px;
  transition: color 0.2s, border 0.2s;
  color: ${black2};
  cursor: pointer;
  text-decoration: none;

  &:hover {
    border-bottom-color: ${brandColor3Tint};
  }

  &.active {
    border-bottom-color: ${brandColor3};
    color: ${black1};
  }

  &:not(:last-child) {
    margin-right: 16px;
  }

  @media (${mediaMedium}) {
    &:not(:last-child) {
      margin-right: 36px;
    }
  }

  @media (max-width: 375px) {
    font-size: 13px;
  }
`;

const PublisherGenContent = ({ setFormSubmitted }) => {
  return (
    <>
      <Container>
        <Center>
          <Title className="active" as="span">
            Talk to the team
          </Title>

          <Title
            href="https://partners.stackcommerce.com/affiliates/new"
            target="_blank"
            rel="noopener noreferrer"
            as="a"
          >
            Create an affiliate account
          </Title>
        </Center>
      </Container>

      <FormContainer>
        <TalkToUsPublishers setFormSubmitted={setFormSubmitted} />
      </FormContainer>
    </>
  );
};

export default PublisherGenContent;
