import React from "react";
import Select, { components } from "react-select";
import { black1, black2, gray1, gray4, white } from "../styles/Colors";
import { Body18 } from "../styles/BodyTypes";
import IconCaretDown from "../icons/IconCaretDown";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <IconCaretDown color={props.isDisabled ? gray1 : black1} />
    </components.DropdownIndicator>
  );
};

const customStyles = {
  menu: (provided) => {
    return {
      ...provided,
      backgroundColor: gray4,
      boxShadow: "none",
      marginBottom: 1,
      marginTop: 1,
      width: "100%",
      color: black1,
      padding: 0
    };
  },
  option: (provided, state) => {
    return {
      ...provided,
      color: state.isSelected || state.isFocused ? white : black1,
      backgroundColor: state.isSelected || state.isFocused ? black1 : white,
      padding: "11px 12px 8px 26px",
      cursor: "pointer"
    };
  },
  control: (provided) => {
    return {
      ...provided,
      backgroundColor: gray4,
      minHeight: 20,
      padding: "22px 0 19px",
      cursor: "pointer",
      boxShadow: "none"
    };
  },
  menuList: (provided) => {
    return {
      ...provided,
      backgroundColor: gray4,
      paddingBottom: 0,
      paddingTop: 0,
      boxShadow: "20px 20px 30px 0 rgba(0,0,0,0.10);"
    };
  },
  valueContainer: (provided) => {
    return {
      ...provided,
      padding: "0 0 0 23px"
    };
  },
  indicatorSeparator: (provided) => {
    return {
      ...provided,
      display: "none"
    };
  },
  dropdownIndicator: (provided) => {
    return {
      ...provided,
      padding: "0",
      margin: "0 24px 0 20px"
    };
  }
};

const theme = (theme) => ({
  ...theme,
  borderRadius: 0,
  colors: {
    ...theme.colors,
    primary25: gray4,
    primary: black1,
    neutral50: black2 // overriding neutral50 because default contrast is insufficient
  }
});

const SelectInput = ({ ...props }) => {
  return (
    <Body18 as="div" theme={{ margins: "0" }}>
      <Select
        components={{ DropdownIndicator }}
        isSearchable={false}
        theme={theme}
        styles={customStyles}
        {...props}
      />
    </Body18>
  );
};

export default SelectInput;
