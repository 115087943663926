import React, { useState } from "react";
import FormContainer from "./FormContainer";
import BrandFormDevelopers from "./BrandFormDevelopers";
import styled from "styled-components";
import { gray4 } from "../styles/Colors";
import SelectInput from "./SelectInput";
import { Label, Wrapper } from "./SharedStyles";
import BrandFormInstructor from "./BrandFormInstructor";
import BrandFormDistribution from "./BrandFormDistribution";

const Background = styled.div`
  background-color: ${gray4};
  padding: 80px 0;
`;

const selectOptions = [
  {
    label: "Digital Products",
    value: "Digital Products"
  },
  {
    label: "Online Courses",
    value: "Online Courses"
  },
  {
    label: "Physical Products",
    value: "Physical Products"
  }
];

const BrandFormSelection = ({ setFormSubmitted }) => {
  const [currentBrand, setCurrentBrand] = useState("");
  const handleChange = (e) => {
    setCurrentBrand(e);
  };

  return (
    <Background>
      <FormContainer>
        <Wrapper>
          <Label as="span">I want to sell</Label>
          <SelectInput
            options={selectOptions}
            value={currentBrand}
            onChange={handleChange}
          />
        </Wrapper>

        {currentBrand !== "" && currentBrand.value === "Online Courses" && (
          <BrandFormInstructor setFormSubmitted={setFormSubmitted} />
        )}
        {currentBrand.value === "Digital Products" && (
          <BrandFormDevelopers setFormSubmitted={setFormSubmitted} />
        )}

        {currentBrand.value === "Physical Products" && <BrandFormDistribution setFormSubmitted={setFormSubmitted} />}
      </FormContainer>
    </Background>
  );
};

export default BrandFormSelection;
