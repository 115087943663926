import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import FormsWrapper from "../components/forms/FormsWrapper";

const Contact = ({ location }) => {
  const formToOpen =
    location.state && location.state.from ? location.state.from : "publisher";
  return (
    <Layout>
      <Seo
        title="Partner With Us: StackCommerce"
        description=" Ready to learn more about StackCommerce? Publishers and brands can submit their information and a representative will reach out."
      />

      <FormsWrapper formToOpen={formToOpen} />
    </Layout>
  );
};

export default Contact;
