import React, { PureComponent } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import InputFormik from "./InputFormik";
import SelectField from "./SelectField";
import { Wrapper, Button } from "./SharedStyles";
import CheckBox from "./Checkbox";
import TextAreaFormik from "./TextAreaFormik";
import CTALink from "../styles/CTALink";
import sendForm from "../helpers/sendForm";
import apiEndpoints from "../helpers/apiEndpoints";
import queryString from "query-string"

import ContactError from "./ContactError";

const selectOptions = [
  {
    label: "Business",
    value: "Business"
  },
  {
    label: "Design",
    value: "Design"
  },
  {
    label: "Development",
    value: "Development"
  },
  {
    label: "Finance",
    value: "Finance"
  },
  {
    label: "IT + Security",
    value: "IT + Security"
  },
  {
    label: "Marketing",
    value: "Marketing"
  },
  {
    label: "Photography",
    value: "Photography"
  },
  {
    label: "Self",
    value: "Self"
  },
  {
    label: "Entertainment",
    value: "Entertainment"
  },
  {
    label: "Languages",
    value: "Languages"
  }
];

const validationSchema = Yup.object({
  name: Yup.string().required(),
  company: Yup.string().required("company name is a required field"),
  email: Yup.string().email().required()
})

class BrandFormInstructor extends PureComponent {
  constructor (props) {
    super(props);

    this.state = { isDisabled: true, isError: false };
    this.toggleAcceptTerms = this.toggleAcceptTerms.bind(this);
  }

  toggleAcceptTerms () {
    this.setState({ isDisabled: !this.state.isDisabled })
  }

  setError() {
    this.setState({ ...this.state, isError: true })
  }

  render () {
    return (
      <>
        <Formik
          initialValues={{
            product_type: "Online Courses",
            name: "",
            email: "",
            company: "",
            product_url: "",
            newsletter_opt_in: "",
            accepted_terms: "",
            course_category: "",
            message: "",
          menu_source: ""
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            const apiUrl = `${apiEndpoints.API_URL}/partners/notifications/contact?category=instructor`;
            const data = values;

            data.course_category = data.course_category.toString();

            const queryParams = queryString.parse(window.location.search);
            data.menu_source = queryParams.smopt || '';

            await sendForm(
              apiUrl,
              { instructor_contact: data },
              setSubmitting,
              this.props.setFormSubmitted
            ).catch(() => this.setError());
          }}
        >
          <Form>
            <InputFormik
              label="Name"
              name="name"
              type="text"
              placeholder="Johnny Smith"
              isRequired
            />

            <InputFormik
              label="Email"
              name="email"
              type="email"
              placeholder="johnny@email.com"
              isRequired
            />

            <InputFormik
              label="Company Name"
              name="company"
              type="text"
              placeholder="Coder's Training Academy"
              isRequired
            />

            <SelectField
              id="courseMaterial"
              name="course_category"
              label="Course material"
              placeholder="Select One"
              options={selectOptions}
              isMulti={false}
              isClearable={false}
              backspaceRemovesValue={true}
            />

            <InputFormik
              label="Course Link"
              name="product_url"
              type="text"
              placeholder="www.mycourselink.com"
            />

            <TextAreaFormik
              label="Have you worked with other sites? If so, which ones?"
              name="message"
              placeholder="Udemy, Creative Live, etc"
            />

            <Wrapper>
              <div />
              <div>
                <CheckBox
                  name="newsletter_opt_in"
                  type="checkbox"
                  title="Sign up for our newsletter"
                  value="yes"
                />
                <CheckBox
                  name="accepted_terms"
                  type="checkbox"
                  onClick={this.toggleAcceptTerms}
                  title={
                    <>
                      I Agree to the Instructor{" "}
                      <CTALink attrs={{ to: "/instructor-terms/" }}>
                        Terms of Use
                      </CTALink>
                    </>
                  }
                  value="yes"
                />
              </div>
            </Wrapper>

            { this.state.isError && <ContactError/> }

            { !this.state.isDisabled &&
              <Wrapper>
                <div />
                <Button bold as="input" type="submit" />
              </Wrapper>
            }
          </Form>
        </Formik>
      </>
    )
  };
};

export default BrandFormInstructor;
