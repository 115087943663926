import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import InputFormik from "./InputFormik";
import CheckBoxGroup from "./CheckBoxGroup";
import SelectField from "./SelectField";
import { Wrapper, Button } from "./SharedStyles";
import CheckBox from "./Checkbox";
import sendForm from "../helpers/sendForm";
import apiEndpoints from "../helpers/apiEndpoints";
import queryString from "query-string"

import ContactError from "./ContactError";

const interestSelectOptions = [
  {
    title: "Shops",
    value: "Shops"
  },
  {
    title: "Affiliate",
    value: "Affiliate"
  },
  {
    title: "StackMedia",
    value: "StackMedia"
  },
  {
    title: "I'm Not Sure",
    value: "I'm Not Sure"
  }
];

const selectOptions = [
  {
    label: "Search Engine",
    value: "Search Engine"
  },
  {
    label: "Recommended by a Friend or Colleague",
    value: "Recommended by a Friend or Colleague"
  },
  {
    label: "Social Media",
    value: "Social Media"
  },
  {
    label: "Blog or publication",
    value: "Blog or publication"
  },
  {
    label: "Webinar or Event",
    value: "Webinar or Event"
  },
  {
    label: "Social Media",
    value: "Social Media"
  },
  {
    label: "Advertisement",
    value: "Advertisement"
  },
  {
    label: "I’m not sure",
    value: "I’m not sure"
  },
  {
    label: "Other (open-ended)",
    value: "Other (open-ended)"
  }
];

const validationSchema = Yup.object({
  name: Yup.string().required(),
  company: Yup.string().required("company name is a required field"),
  email: Yup.string().email().required()
})

const TalkToUsPublishers = ({ setFormSubmitted }) => {
  const [isError, setError] = useState(false);

  return (
    <>
      <Formik
        initialValues={{
          name: "",
          company: "",
          email: "",
          website: "",
          interest: "",
          social_followers: "",
          audience_size: "",
          phone: "",
          referral: "",
          email_newsletter: "",
          menu_source: ""
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          const apiUrl = `${apiEndpoints.API_URL}/partners/notifications/contact?category=publisher`;
          const data = values;

          const queryParams = queryString.parse(window.location.search);
          data.menu_source = queryParams.smopt || '';

          await sendForm(
            apiUrl,
            { publisher_contact: data },
            setSubmitting,
            setFormSubmitted
          ).catch(() => setError(true));
        }}
      >
        <Form>
          <InputFormik
            label="Company Name"
            name="company"
            type="text"
            placeholder="My awesome company"
            isRequired
          />

          <InputFormik
            label="Website"
            name="website"
            type="text"
            placeholder="www.website.com"
          />

          <InputFormik
            label="Monthly unique visitors"
            name="audience_size"
            type="text"
            placeholder="Approx number"
          />

          <InputFormik
            label="Social followers"
            name="social_followers"
            type="text"
            placeholder="Approx Number"
          />

          <CheckBoxGroup
            label="Do you have an email newsletter?"
            name="email_newsletter"
            type="radio"
            options={[
              {
                title: "Yes",
                value: "Yes"
              },
              {
                title: "No",
                value: "No"
              }
            ]}
          />

          <CheckBoxGroup
            label="Interested in"
            name="interest"
            type="radio"
            options={interestSelectOptions}
          />

          <InputFormik
            label="Name"
            name="name"
            type="text"
            placeholder="Johnny Smith"
            isRequired
          />

          <InputFormik
            label="Email"
            name="email"
            type="email"
            placeholder="johnny@email.com"
            isRequired
          />

          <InputFormik
            label="Phone number"
            name="phone"
            type="text"
            placeholder="(555) 555-555"
          />

          <SelectField
            id="referral"
            name="referral"
            label="How did you hear about Stack?"
            placeholder="Select One"
            options={selectOptions}
            isMulti={false}
            isClearable={false}
            backspaceRemovesValue={true}
          />

          <Wrapper>
            <div />
            <div>
              <CheckBox
                type="checkbox"
                name="newsletter_opt_in"
                title="Sign up for our newsletter"
                value="yes"
              />
            </div>
          </Wrapper>

          { isError && <ContactError/> }

          <Wrapper>
            <div />
            <Button bold as="input" type="submit" />
          </Wrapper>
        </Form>
      </Formik>
    </>
  );
};

export default TalkToUsPublishers;
