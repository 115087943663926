import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import InputFormik from "./InputFormik";
import CheckBoxGroup from "./CheckBoxGroup";
import SelectField from "./SelectField";
import { Wrapper, Button, Label } from "./SharedStyles";
import CheckBox from "./Checkbox";
import sendForm from "../helpers/sendForm";
import apiEndpoints from "../helpers/apiEndpoints";
import queryString from "query-string"

import ContactError from "./ContactError";

const selectOptions = [
  {
    label: "Under $5,000",
    value: "Under $5,000"
  },
  {
    label: "$5,000 - $25,000",
    value: "$5,000 - $25,000"
  },
  {
    label: "$25,000 - $50,000",
    value: "$25,000 - $50,000"
  },
  {
    label: "$50,000 - $100,000",
    value: "$50,000 - $100,000"
  },
  {
    label: "$100,000 +",
    value: "$100,000 +"
  }
];

const validationSchema = Yup.object({
  name: Yup.string().required(),
  email: Yup.string().email().required(),
  website: Yup.string().required()
})

const BrandFormDistribution = ({ setFormSubmitted }) => {
  const [isError, setError] = useState(false);

  return (
    <>
      <Formik
        initialValues={{
          product_type: "Physical Products",
          name: "",
          email: "",
          website: "",
          product: "",
          budget: "",
          interest: "",
          company: "",
          phone: "",
          newsletter_opt_in: "",
          menu_source: ""
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          let apiUrl = `${apiEndpoints.API_URL}/partners/notifications/contact`;
          let contactData;

          const data = values;

          const queryParams = queryString.parse(window.location.search);
          data.menu_source = queryParams.smopt || '';

          if (data.interest.includes('StackMedia')) {
            apiUrl += '?category=stackmedia';
            contactData = { stackmedia_contact: data }
          } else {
            apiUrl += '?category=vendor';
            delete data.budget;
            contactData = { vendor_contact: data }
          }

          await sendForm(
            apiUrl,
            contactData,
            setSubmitting,
            setFormSubmitted
          ).catch(() => setError(true));
        }}
      >
        <Form>
          <InputFormik
            label="Website"
            name="website"
            type="text"
            placeholder="www.website.com"
            isRequired
          />

          <InputFormik
            label="Product Name"
            name="product"
            type="text"
            placeholder="My Awesome Product"
          />

          <Wrapper>
            <Label style={{ alignSelf: "flex-start" }} as="span">
              Interested in
            </Label>
            <div>
              <CheckBox
                name="interest"
                type="radio"
                title="Shops"
                value="Shops"
              />
              <CheckBox
                name="interest"
                type="radio"
                title="StackMedia"
                value="StackMedia"
              >
                <SelectField
                  id="budgetMonthly"
                  name="budget"
                  onlyField={true}
                  placeholder="Select Monthly Budget"
                  options={selectOptions}
                  isMulti={false}
                  isClearable={false}
                  backspaceRemovesValue={true}
                />
              </CheckBox>
              <CheckBox
                name="interest"
                type="radio"
                title="I'm Not Sure"
                value="I'm Not Sure"
              />
            </div>
          </Wrapper>

          <InputFormik
            label="Name"
            name="name"
            type="text"
            placeholder="Johnny Smith"
            isRequired
          />

          <InputFormik
            label="Company Name"
            name="company"
            type="text"
            placeholder="Company"
          />

          <InputFormik
            label="Email"
            name="email"
            type="email"
            placeholder="johnny@email.com"
            isRequired
          />

          <InputFormik
            label="Phone number"
            name="phone"
            type="text"
            placeholder="(555) 555-555"
          />

          <CheckBoxGroup
            label=""
            name="newsletter_opt_in"
            type="checkbox"
            options={[
              {
                title: "Sign up for our newsletter",
                value: "yes"
              }
            ]}
          />

          { isError && <ContactError/> }

          <Wrapper>
            <div />
            <Button bold as="input" type="submit" />
          </Wrapper>
        </Form>
      </Formik>
    </>
  );
};

export default BrandFormDistribution;
