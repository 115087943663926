import React from "react";
import styled from "styled-components";
import { fontCatamara } from "./styles/variables";

const Wrapper = styled.div`
  background: #fac901;
  border-bottom: 1px solid #ababab;
  font-family: ${fontCatamara};
  margin-bottom: 10px;
  padding: 10px;
`;

const Alert = ({ children }) => {
  return (
    <Wrapper>
      {children}
    </Wrapper>
  );
};

export default Alert;
