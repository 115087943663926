import React from "react";
import styled from "styled-components";
import { useField } from "formik";
import { Error, Wrapper, Label, Group, StyledInput } from "./SharedStyles";
import { mediaMedium } from "../styles/variables";

const TextArea = styled(StyledInput)`
  min-height: 80px;
  height: auto;
  padding-top: 15px;
  padding-bottom: 15px;
  resize: vertical;
  line-height: 1.3;

  @media (${mediaMedium}) {
    min-height: 112px;
  }
`;

const TextAreaFormik = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <Wrapper style={{ alignItems: "flex-start" }}>
      <Label as="label" htmlFor={props.id || props.name}>
        {label}
      </Label>
      <Group>
        <TextArea as="textarea" {...field} {...props} />
        {meta.touched && meta.error ? <Error>{meta.error}</Error> : null}
      </Group>
    </Wrapper>
  );
};

export default TextAreaFormik;
