import React from "react";

const IconCaretDown = ({ color }) => {
  return (
    <svg width="12" height="7" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.4-.06L6 4.54l4.6-4.6L11.66 1l-4.6 4.6L6 6.66.34 1 1.4-.06z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default IconCaretDown;
