import React from "react";
import uniqueId from "lodash/uniqueId";
import CheckBox from "./Checkbox";
import { Wrapper, Label } from "./SharedStyles";

const CheckBoxGroup = ({ options, label, ...props }) => {
  return (
    <Wrapper>
      <Label style={{ alignSelf: "flex-start" }} as="label">
        {label}
      </Label>
      <div>
        {options.map((item, key) => {
          const id = uniqueId("ckg" + key);
          return <CheckBox {...props} {...item} key={id} />;
        })}
      </div>
    </Wrapper>
  );
};

export default CheckBoxGroup;
