import React from "react";
import styled from "styled-components";
import { white } from "../styles/Colors";
import { mediaMedium } from "../styles/variables";

const Container = styled.div`
  max-width: 1144px;
  width: 100%;
  margin: 0 auto;

  @media (${mediaMedium}) {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const Center = styled.div`
  margin: 0 auto;
  background-color: ${white};
  padding: 40px 27px 45px;

  @media (${mediaMedium}) {
    padding: 52px 58px;
    width: calc(100% * 8 / 12 + 28px);
  }
`;

const FormContainer = ({ children }) => {
  return (
    <Container>
      <Center>{children}</Center>
    </Container>
  );
};

export default FormContainer;
