import React from "react";
import Seo from "../components/Seo";
import styled from "styled-components";
import { gray4 } from "../components/styles/Colors";
import FormContainer from "../components/forms/FormContainer";
import { Body18 } from "../components/styles/BodyTypes";

const Background = styled.div`
  background-color: ${gray4};
  padding: 80px 0;
`;

const Submission = () => (
  <>
    <Seo
      title="Thank You for Contacting Us: StackCommerce"
      titleTemplate=""
      description="Thank you for your interest in learning more about StackCommerce. We will be in touch and hope to partner with you soon."
    />
    <Background>
      <FormContainer>
        <Body18>Thanks for getting in touch!</Body18>
        <Body18>
          We will take a look at your inquiry and be in touch soon.
        </Body18>
      </FormContainer>
    </Background>
  </>
);

export default Submission;
