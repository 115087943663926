import React from "react";
import { useField } from "formik";
import { Error, Wrapper, Label, Group } from "./SharedStyles";
import SelectInput from "./SelectInput";

const SelectField = ({ label, id, onlyField, ...props }) => {
  const [, meta, helpers] = useField({ ...props, type: "select" });
  const handleChange = (e) => {
    helpers.setValue(e.value);
  };

  const labelId = `label-${id}`;

  if (onlyField === true) {
    return (
      <Group>
        <SelectInput {...props} id={id} onChange={handleChange} />

        {meta.touched && meta.error ? (
          <Error className="error-text">{meta.error}</Error>
        ) : null}
      </Group>
    );
  }

  return (
    <Wrapper>
      <Label as="label" id={labelId}>
        {label}
      </Label>
      <Group>
        <SelectInput
          {...props}
          id={id}
          aria-labelledby={labelId}
          onChange={handleChange}
        />

        {meta.touched && meta.error ? (
          <Error className="error-text">{meta.error}</Error>
        ) : null}
      </Group>
    </Wrapper>
  );
};

export default SelectField;
