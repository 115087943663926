import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import {
  brandColor1,
  brandColor1Shade,
  brandColor1Tint,
  gray2,
  white
} from "./Colors";

const VARIANTS = {
  shade: {
    color: brandColor1Shade,
    hoverColor: brandColor1
  },
  tint: {
    color: brandColor1Tint,
    hoverColor: brandColor1
  },
  white: {
    color: white,
    hoverColor: gray2
  }
};

const InlineLink = styled.a`
  display: ${(props) => props.display ?? "inline"};
  text-decoration: ${(props) => (props.$underlined ? "underlined" : "none")};
  font-weight: ${(props) => (props.$bold ? 600 : 300)};
  color: ${(props) => VARIANTS[props.variant].color};
  transition: color 0.2s;

  svg {
    margin-left: 16px;
  }

  &:hover {
    cursor: pointer;
    color: ${(props) => VARIANTS[props.variant].hoverColor};
  }
`;

const CTALink = ({
  attrs,
  display,
  variant = "shade",
  children,
  bold = false,
  underlined = false
}) => {
  const tag = attrs && attrs.to ? Link : "a";

  return (
    <InlineLink
      display={display}
      as={tag}
      variant={variant}
      $bold={bold}
      $underlined={underlined}
      {...attrs}
    >
      {children}
    </InlineLink>
  );
};

export default CTALink;
