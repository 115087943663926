import { navigate } from "gatsby";

const getHeaders = () => ({
  mode: "cors",
  redirect: "follow",
  headers: new Headers({ "Content-Type": "application/json" })
})

const request = (url, method, body = {}) => {
  const params = { ...getHeaders(), method: method, body: JSON.stringify(body) };
  return fetch(url, params).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  })
}

const post = (url, body = {}) => {
  return request(url, 'POST', body);
}

const sendForm = async (url, data, setSubmitting, setFormSubmitted) => {
  setFormSubmitted(false);
  setSubmitting(false);

  return post(url, data)
    .then(() => setFormSubmitted(true))
    .then(() => navigate("/contact/"))
    .catch((error) => {
      setSubmitting(true)

      throw error;
    });
};

export default sendForm;
